<template>
  <div class="register">
    <div class="header">
      <div class="content">
        <div class="logo">199</div>
        ｜
        <div class="menu">注册</div>
      </div>
    </div>
    <div class="steps">
      <el-steps
        :active="steps"
        align-center
        finish-status="success"
        process-status="process"
      >
        <el-step
          title="邮箱验证"
          description="进入邮箱，输入邮箱验证码完成验证"
        ></el-step>
        <el-step
          title="注册提交"
          description="请提交真实信息，虚假信息一律不通过审核"
        ></el-step>
        <el-step
          title="等待审核"
          description="为加快审核，请添加客服微信"
        ></el-step>
        <el-step title="注册完成" description="注册完成"></el-step>
      </el-steps>
    </div>
    <div class="register-container">
      <div class="box">
        <el-form
          v-if="stepName == 'email'"
          @submit.native.prevent
          :model="registerModel"
          :rules="rulesEmail"
          label-width="100px"
          ref="registerEmail"
        >
          <el-form-item label="注册邮箱" prop="email">
            <el-input
              type="email"
              v-model="registerModel.email"
              autocomplete="off"
              class="bold"
              placeholder="请输入真实邮箱，稍后通过邮箱验证码验证"
            ></el-input>
          </el-form-item>
          <div class="forgetpwd" v-if="isRegisterUser">
            您的邮箱已注册，如忘记密码可以点击<span
              class="but"
              @click="handleForgetPassword"
              >重置密码</span
            >
          </div>
          <el-form-item>
            <div
              :class="['next-but', nextStep ? 'active' : '']"
              @click="handleNextStepCode"
            >
              下一步
            </div>
          </el-form-item>
          <div class="txt-right">已有账户，<a target="_blank" href="https://199.jp/home/index">去登录</a></div>
        </el-form>
        <el-form
          v-if="stepName == 'code'"
          @submit.native.prevent
          :model="registerModel"
          :rules="rulesCode"
          label-width="100px"
          ref="registerCode"
        >
          <el-alert
            title="验证码已发送邮箱"
            type="success"
            show-icon
            :closable="false"
          >
          </el-alert>
          <el-form-item label="邮箱验证码" prop="emailCode">
            <el-input
              v-model="registerModel.emailCode"
              autocomplete="off"
              class="bold"
              placeholder="请输入邮箱收到的验证码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="buts-box">
              <div
                :class="['next-but', nextStep ? 'active' : '']"
                @click="handleNextStepInfo"
              >
                下一步
              </div>
              <div @click="handleSendEmail" class="next-but send">重新发送</div>
            </div>
          </el-form-item>
        </el-form>
        <el-form
          v-if="stepName == 'info'"
          @submit.native.prevent
          :model="registerModel"
          :rules="rulesInfo"
          label-width="100px"
          ref="registerInfo"
        >
          <el-form-item label="注册邮箱" prop="emailCode">
            <div class="register-email">
              <span>{{ registerModel.email }}</span>
              <icon class="iconfont icon-chenggong"></icon>
            </div>
          </el-form-item>
          <el-form-item label="公司名称" prop="company">
            <el-input
              v-model="registerModel.company"
              autocomplete="off"
              class="bold"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="username">
            <el-input
              v-model="registerModel.username"
              autocomplete="off"
              class="bold"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系地址" prop="address">
            <el-input
              v-model="registerModel.address"
              autocomplete="off"
              class="bold"
              placeholder="请输入联系地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="其他说明" prop="note">
            <el-input
              type="textarea"
              v-model="registerModel.note"
              autocomplete="off"
              class="bold"
              placeholder="填写补充内容说明"
              :autosize="{ minRows: 4 }"
              maxlength="500"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="buts-box">
              <div
                :class="['next-but', nextStep ? 'active' : '']"
                @click="handleSubmitModel"
              >
                提交注册
              </div>
              <div @click="handleUpStep" class="next-but send">上一步</div>
            </div>
          </el-form-item>
        </el-form>
        <div v-if="stepName == 'check'" class="register-check">
          <div class="message">
            <div><icon class="iconfont icon-chenggong"></icon></div>
            <div>
              <div>
                注册提交完成，请等待客户经理审核。审核通过我们会邮件通知您，请注意查收邮件!
              </div>
              <div>添加客户经理微信可以加快审核</div>
            </div>
          </div>
          <!-- <div class="wximg">
            <img
              src="../../assets//images/customer-service.jpg"
              alt="客户经理微信"
            />
          </div> -->
        </div>
      </div>
      <div class="register-helper" v-if="stepName != 'check'">
        <div class="message">
          <div>
            如注册遇到问题，请发邮件<a href="mailto:kefu@199.jp"
              >kefu@199.jp</a
            >寻求帮助！
          </div>
          <div>或添加客户经理微信寻求帮助</div>
        </div>
        <!-- <div class="wximg">
          <img
            src="../../assets//images/customer-service.jpg"
            alt="客户经理微信"
          />
        </div> -->
      </div>
    </div>
    <div class="hoho-footer">© 199 2018-2023</div>
  </div>
</template>

<script>
import Register from 'api/register'
import { vxRule, rules } from '@/utils/validator'
export default {
  components: {},
  name: '',
  data() {
    let verifyRegisterEmail = (rule, value, callback) => {
      if (value) {
        let { result, errMsg } = rules.Email(value)
        if (result) {
          callback()
        } else {
          callback(new Error(errMsg))
        }
      } else {
        callback(new Error('请输入注册邮箱'))
      }
    }
    return {
      nextStep: true,
      steps: 0,
      stepName: 'email', // 当前步骤输入注册邮箱，code:输入邮箱验证码，info: 提交注册，check:等待审核
      isRegisterUser: false,
      registerModel: {
        email: '', // 邮箱
        emailCode: '', // 邮箱验证码
        company: '', // 公司
        username: '', // 联系人
        address: '', // 联系地址
        note: '' // 其他说明
      },
      rulesEmail: {
        email: [
          { validator: verifyRegisterEmail, required: true, trigger: 'change' }
        ]
      },
      rulesCode: {
        emailCode: vxRule(true, null, 'blur', '请输入邮箱验证码')
      },
      rulesInfo: {
        company: vxRule(true, null, 'blur', '请输入公司名称'),
        username: vxRule(true, null, 'blur', '请输入联系人'),
        address: vxRule(true, null, 'blur', '请输入联系地址')
      },
      sending: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    // 上一步
    handleUpStep() {
      this.stepName = 'code'
      this.steps = 0
    },
    // 重新发送邮件
    handleSendEmail() {
      if (!this.sending) {
        this.sending = true
        Register.sendEmail({
          email: this.registerModel.email,
          source: 'register'
        }).then((res) => {
          this.sending = false
          if (res.success) {
            this.$message.success('邮件已发送')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 提交注册
    handleSubmitModel() {
      this.$refs.registerInfo.validate((valid) => {
        if (valid) {
          Register.register({
            email: this.registerModel.email,
            code: this.registerModel.emailCode,
            company: this.registerModel.company,
            contactuser: this.registerModel.username,
            address: this.registerModel.address,
            note: this.registerModel.note,
            source: 'register'
          }).then((res) => {
            if (res.success) {
              this.stepName = 'check'
              this.steps = 2
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 验证通过填写公司信息
    handleNextStepInfo() {
      this.$refs.registerCode.validate((valid) => {
        if (valid) {
          Register.verifySendCode({
            email: this.registerModel.email,
            code: this.registerModel.emailCode,
            source: 'register'
          }).then((res) => {
            if (res.success) {
              this.stepName = 'info'
              this.steps = 1
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 输入邮箱点击下一步
    handleNextStepCode() {
      this.$refs.registerEmail.validate((valid) => {
        if (valid) {
          Register.existEmail({ email: this.registerModel.email }).then(
            (res) => {
              if (res.record) {
                this.isRegisterUser = true
              } else {
                Register.sendEmail({
                  email: this.registerModel.email,
                  source: 'register'
                }).then((res) => {
                  if (res.success) {
                    this.stepName = 'code'
                  } else {
                    this.$message.error(res.message)
                  }
                })
              }
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 点击重置密码
    handleForgetPassword() {
      this.$router.push('/forgetpwd?email=' + this.registerModel.email)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.register {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header {
    background: rgb(77, 28, 138);
    height: 75px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 970px;
      display: flex;
      align-items: center;
      font-size: 17px;
      .logo {
        font-size: 28px;
        font-weight: bold;
        font-family: Tahoma, Helvetica, Arial;
        margin-right: 10px;
      }
      .menu {
        margin-left: 10px;
      }
    }
  }
  .steps {
    width: 970px;
    margin: auto;
    padding: 40px 0 50px;
    background: #fff;
  }
  .register-container {
    flex: 1;
    width: 970px;
    background: #fff;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .box {
      width: 600px;
      padding: 60px 40px 40px;
      border-radius: 10px;
      margin-bottom: 40px;
      position: relative;
      box-shadow: 1px 1px 5px -1px #999;
      background: #f9f9f9;
      .bold {
        font-weight: bold;
      }
      .next-but {
        height: 36px;
        color: #fff;
        background: #ff6200;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        opacity: 0.5 !important;
        cursor: pointer !important;
        font-weight: bold;
      }
      .next-but.active {
        opacity: 1 !important;
      }
      .forgetpwd {
        margin-left: 100px;
        font-size: 12px;
        margin-bottom: 10px;
        .but {
          color: rgb(77, 28, 138);
          margin-left: 2px;
          cursor: pointer !important;
        }
      }
      .register-email {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        background-color: #f0f9eb;
        color: #67c23a;
        border-radius: 5px;
        width: 400px;
        text-indent: 15px;
        .iconfont {
          margin-right: 15px;
        }
      }
    }
    .register-helper {
      display: flex !important;
      align-items: center;
      margin-bottom: 20px;
      .message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 80px;
        color: #aaa;
        background: #f9f9f9;
        padding: 0 20px;
        a {
          padding: 0 5px;
          text-decoration: underline;
          color: rgb(77, 28, 138);
        }
      }
      .wximg {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 70px;
        }
      }
    }
    .register-check {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .message {
        background-color: #f0f9eb;
        color: #67c23a;
        height: 80px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        width: 60%;
        .iconfont {
          font-size: 40px;
          margin-right: 10px;
        }
      }
      .wximg {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 70px;
        }
      }
    }
  }
  .buts-box {
    display: flex;
    .next-but.send {
      margin: 0 20px;
      background: #fff;
      color: #ff6200;
      outline: 1px solid #ff6200;
    }
  }
}
.hoho-footer {
  background: rgb(77, 28, 138);
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 12px;
}
.txt-right{
  text-align: right;
}
::v-deep {
  .el-input,
  .el-textarea {
    width: 400px;
  }
  .el-input__inner,
  .el-textarea__inner {
    font-weight: bold;
  }
  .el-form-item__label {
    font-weight: bold;
  }
  .el-alert {
    width: 400px;
    margin-left: 100px;
    position: absolute;
    top: 25px;
  }
}
</style>
